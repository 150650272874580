<template>
  <div class="location-page">
    <main-location-block />
    <location-block width initial-tab="Удобство" />
    <image-gallery-block
      hide-page-title
      initial-tab="Архитектура"
      :title-shown="false"
    />
    <best-apartments-block />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import MainLocationBlock from "@/components/blocks/MainLocationBlock";
import ImageGalleryBlock from "@/components/blocks/ImageGalleryBlock";
import LocationBlock from "@/components/blocks/LocationBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";

export default {
  name: "LocationPage",
  components: {
    BestApartmentsBlock,
    MainLocationBlock,
    ImageGalleryBlock,
    LocationBlock,
    CallbackBlock,
  },
  data: () => {
    return {
      block: true,
    };
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "ЖК MainStreet - Кутузовский пр. и м. Кунцевская, каскад парков и фитнес-клубы, школы и торговые центры – все для комфортной жизни, развития и гармонии.",
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
.location-page {
  .location {
    background: #231f20;
  }

  .location__box {
    width: 100%;
  }

  .image-gallery-block {
    padding-top: 40px;

    @media screen and (min-width: $screen-sm) {
      padding-top: 60px;
    }
  }

  .offers {
    padding: 15px 0 60px 0;
  }
}
</style>
