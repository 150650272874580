<template>
  <div class="location-block">
    <content-wrapper-block>
      <div class="location-block__content">
        <bread-crumbs />
        <page-title white> Расположение </page-title>
        <time-block :items="items" mobile>
          <template slot="block">
            ЖК Mainstreet - это идеальное место для тех, кто ценит мобильность,
            эстетику и возможность комфортного отдыха. Расположен на пересечении
            Рублевского шоссе и Кутузовского проспекта.
          </template>
        </time-block>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import TimeBlock from "@/components/elements/TimeBlock";
import BreadCrumbs from "@/components/elements/BreadCrumbs";

export default {
  name: "MainLocationBlock",
  components: {
    BreadCrumbs,
    ContentWrapperBlock,
    PageTitle,
    TimeBlock,
  },
  data() {
    return {
      items: [
        {
          big: "5",
          normal: "минут",
          text: "от парка",
        },
        {
          big: "15",
          normal: "минут",
          text: "от центра",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.location-block {
  background: #231f20;

  &__content {
    .time-block__container {
      margin-top: 17px;

      @media screen and (min-width: $screen-sm) {
        margin-top: 50px;
      }
    }
  }
}
</style>
