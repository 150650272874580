var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "location-block" },
    [
      _c("content-wrapper-block", [
        _c(
          "div",
          { staticClass: "location-block__content" },
          [
            _c("bread-crumbs"),
            _c("page-title", { attrs: { white: "" } }, [
              _vm._v(" Расположение "),
            ]),
            _c(
              "time-block",
              { attrs: { items: _vm.items, mobile: "" } },
              [
                _c("template", { slot: "block" }, [
                  _vm._v(
                    " ЖК Mainstreet - это идеальное место для тех, кто ценит мобильность, эстетику и возможность комфортного отдыха. Расположен на пересечении Рублевского шоссе и Кутузовского проспекта. "
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }