var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "location-page" },
    [
      _c("main-location-block"),
      _c("location-block", { attrs: { width: "", "initial-tab": "Удобство" } }),
      _c("image-gallery-block", {
        attrs: {
          "hide-page-title": "",
          "initial-tab": "Архитектура",
          "title-shown": false,
        },
      }),
      _c("best-apartments-block"),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }